import type { AuthFetch } from '~/types/globalDeclarations/AuthFetch'

/**
 * Provider to log out of the Auth service using email and password.
 *
 * @param { CoreFetch } $coreFetch - The nuxt axios transporter.
 * @param $authFetch
 * @returns { Function } The api call function.
 */
export function provideAuthApiLogout($authFetch: AuthFetch) {
  return async function () {
    try {
      await $authFetch.$post<void>('/api/logout/')
    } catch (_) {}
  }
}
